import React, { useEffect, useState } from "react";
import RenderFields from "./RenderFields";
import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import ActionOptionsTable from "./ActionOptionsTable";
import { getSelectedOptionName } from "../../services/commonFunction";
import {
  labGroupsStdBasisApi,
  labparambasisApi,
  sampleDetailsAPI,
  sampleInwardDetailsGetAPI,
} from "../../services/api";
import { GetTenantDetails, postDataFromApi } from "../../services/commonServices";
import { toast } from "react-toastify";
import { assignmentPageHandleAction } from "./commonHandlerFunction/GroupAssignmentFunctions";
import PropTypes from "prop-types";
import Loader from "./OverlayLoading";
import DeleteConfirmation from "./DeleteConfirmation";
import { setIn } from "formik";

const RenderAssignmentTableSection = ({
  section,
  sectionIndex,
  formData,
  handleFieldChange,
  addRow,
  deleteRow,
  deleteColumn,
  formErrors,
  setFormData,
  popupMessages,
  pageType,
  action,
  masterOptions,
  saveClicked,
  moduleType,
  setTableData,
  getAssignmentMasterData,
  setSaveClicked,
  tableData,
  getSampleIdsMasterData,
  setIsDisplayNewAddOption,
  isDisplayNewAddOption,
  setIsOverlayLoader,
  isOverlayLoader
}) => {
  let EditAction = [
    {
      icon: "bi bi-floppy-fill",
      text: "Save",
    },
    {
      icon: "bi bi-x-circle-fill",
      text: "Cancel",
    },
  ];
  let MainAction = [
    {
      icon: "bi bi-pencil-square",
      text: "Edit",
    },
    {
      icon: "bi bi-trash3-fill",
      text: "Delete",
    },
  ];
  if (pageType == "assignment") {
    MainAction.splice(0, 1);
  }
  if (moduleType === "sampleverification") {
    MainAction.splice(1, 1);
  }
  const initialCustomData = {
    [sectionIndex]: {
      smpl_set_basisjson: [],
      smpl_set_smpljson: [],
      is_group_param: GetTenantDetails(1, 1) == "TPBPL" ? "Parameter" : "Group",
      is_group_param_name: GetTenantDetails(1, 1) == "TPBPL" ? "Parameter" : "Group",
      smpl_set_paramjson: "",
      smpl_set_testmethodjson: "",
      smpl_set_unit: "",
    },
  };
  const [sampleDataTable, setSampleDataTable] = useState([]);
  const [updatedMasterOptions, setUpdatedMasterOptions] = useState([]);
  const [customFormData, setCustomFormData] = useState(initialCustomData);
  const [parameterDataTable, setParameterDataTable] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [popupIndex, setPopupIndex] = useState(-1);
  const [editableIndex, setEditableIndex] = useState("");
  const [simpleInwardId, setSimpleInwardId] = useState("");
  const [groupParameteres, setGroupParameters] = useState([]);
  const [isGroupChanged, setIsGroupChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [finalParamDataSort, setFinalParamDataSort] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      setSimpleInwardId(formData[1]?.sampleInwardIdMain);
      if (formData[1]?.sampleInwardIdMain) {
        getInwardTabledata(formData[1]?.sampleInwardIdMain);
      }
    }, 1000);
  }, [formData[1]?.sampleInwardIdMain]);

  useEffect(() => {
    setUpdatedMasterOptions(masterOptions);
  }, [masterOptions]);
  useEffect(() => {
    const getOnloadFunction = async () => {
      if (
        customFormData[sectionIndex].is_group_param &&
        formData[1]?.sampleInwardIdMain
      ) {
        var newinitialCustomData = initialCustomData;
        newinitialCustomData[sectionIndex].is_group_param =
          customFormData[sectionIndex].is_group_param;
        newinitialCustomData[sectionIndex].smpl_set_smpljson_name =
          customFormData[sectionIndex].smpl_set_smpljson_name;
        newinitialCustomData[sectionIndex].smpl_set_smpljson =
          customFormData[sectionIndex].smpl_set_smpljson;
        newinitialCustomData[sectionIndex].smpl_set_unit =
          customFormData[sectionIndex].smpl_set_unit;
        setCustomFormData(newinitialCustomData);
        setGroupParameters([]);
        setIsLoading(true);
        var isCalled = await getAssignmentMasterData(
          formData[1].jrf_commodity,
          formData[1].jrf_lab,
          customFormData[sectionIndex].is_group_param.toLowerCase()
        );
        if (isCalled) {
          setIsLoading(false);
        }
      }
    };
    getOnloadFunction();
  }, [customFormData[sectionIndex].is_group_param]);
  const moveParamDetails = (index) => {
    setIsDisplayNewAddOption(false);
    let paramDetails = parameterDataTable;
    paramDetails.splice(index, 1);
    setParameterDataTable(paramDetails);
    setTimeout(() => {
      setIsDisplayNewAddOption(true);
    }, 10);
  };
  const addNewParameterDetails = () => {
    let paramaData = customFormData[sectionIndex];
    paramaData.is_group_param_name = paramaData.is_group_param;
    if (
      !paramaData["smpl_set_paramjson"] &&
      !paramaData["smpl_set_groupjson"]
    ) {
      let errLabel = "";
      errLabel = "Groups / Parameters";
      toast.error(errLabel + "  required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (paramaData.is_group_param === "Group") {
      let isTestMethosErr = false;
      let isBasisErr = false;
      groupParameteres.some((param, gpIndex) => {
        var testMethodiId = paramaData["smpl_set_testmethodjson_" + gpIndex];
        var basisIdselect = paramaData["smpl_set_basisjson_" + gpIndex];
        if (testMethodiId === undefined || testMethodiId === "") {
          toast.error("All Test Methods are required", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          isTestMethosErr = true;
          return true;
        } else if (basisIdselect === undefined || basisIdselect.length === 0) {
          toast.error("All Basis are required", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          isBasisErr = true;
          return true;
        }
      });
      if (isBasisErr || isTestMethosErr) {
        return true;
      }
      for (let obj in paramaData) {
        if (
          ![
            "smpl_set_paramjson",
            "smpl_set_unit",
            "smpl_set_paramjson_name",
            "smpl_set_groupjson",
            "smpl_set_groupjson_name",
            "smpl_set_smpljson_name",
            "smpl_set_basisjson_name",
            "smpl_set_testmethodjson_name",
            "smpl_set_basisjson",
            "smpl_set_testmethodjson",
          ].includes(obj) &&
          (paramaData[obj] === undefined || !paramaData[obj].length)
        ) {
          const field = section.rows[0].filter((field, index) => {
            if (field.name === obj) {
              field.label = section.headers[index]
                ? section.headers[index].label
                : obj;
              return true;
            }
          });
          let errLabel = field.length ? field[0].errorlabel : obj;
          if (obj == "smpl_set_smpljson") {
            errLabel = "Samples";
          }
          toast.error(errLabel + "  required", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return;
        }
      }
    } else {
      // commented by DC
      for (let obj in paramaData) {
        if (
          ![
            "smpl_set_paramjson",
            "smpl_set_paramjson_name",
            "smpl_set_groupjson",
            "smpl_set_groupjson_name",
            "smpl_set_smpljson_name",
            "smpl_set_basisjson_name",
            "smpl_set_testmethodjson_name",
          ].includes(obj) &&
          (paramaData[obj] === undefined || !paramaData[obj].length)
        ) {
          const field = section.rows[0].filter((field, index) => {
            if (field.name === obj) {
              field.label = section.headers[index]
                ? section.headers[index].label
                : obj;
              return true;
            }
          });
          let errLabel = field.length ? field[0].errorlabel : obj;
          if (obj == "smpl_set_smpljson") {
            errLabel = "Samples";
          }
          toast.error(errLabel + "  required", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return;
        }
      }
    }
    paramaData.smpl_set_groupjson_name = getSelectedOptionName(
      [],
      updatedMasterOptions,
      "smpl_set_groupjson",
      paramaData["smpl_set_groupjson"],
      "smpl_set_groupjson",
      1
    );
    paramaData.smpl_set_paramjson_name = getSelectedOptionName(
      [],
      updatedMasterOptions,
      "smpl_set_paramjson",
      paramaData["smpl_set_paramjson"],
      "smpl_set_paramjson",
      1
    );

    paramaData.smpl_set_basisjson_name = getSelectedOptionName(
      [],
      updatedMasterOptions,
      "smpl_set_basisjson",
      paramaData["smpl_set_basisjson"],
      "smpl_set_basisjson"
    );
    paramaData.smpl_set_testmethodjson_name = getSelectedOptionName(
      [],
      updatedMasterOptions,
      "smpl_set_testmethodjson",
      paramaData["smpl_set_testmethodjson"],
      "smpl_set_testmethodjson",
      1
    );
    if (paramaData.is_group_param === "Group") {
      let parameters = [];
      groupParameteres.map((param, gpIndex) => {
        let standards = [];
        let basiss = [];
        var stdId = paramaData["smpl_set_testmethodjson_" + gpIndex];
        var stdName = getSelectedOptionName(
          [],
          updatedMasterOptions,
          "smpl_set_testmethodjson_" + gpIndex,
          paramaData["smpl_set_testmethodjson_" + gpIndex],
          "smpl_set_testmethodjson_" + gpIndex,
          1,
          1
        );
        standards.push({
          std_id: stdId,
          std_name: stdName[0],
        });

        var basisId = paramaData["smpl_set_basisjson_" + gpIndex];
        var basisName = getSelectedOptionName(
          [],
          updatedMasterOptions,
          "smpl_set_basisjson_" + gpIndex,
          paramaData["smpl_set_basisjson_" + gpIndex],
          "smpl_set_basisjson_" + gpIndex,
          "",
          1
        );
        basisId.map((id, i) => {
          basiss.push({
            basis_id: id,
            basis_code: basisName[i],
          });
        });
        parameters.push({
          param_name: param.param_name,
          param_unit: paramaData["smpl_set_unit_" + gpIndex],
          param_id: param.param_id,
          standards: standards,
          basis: basiss
        });
      });
      paramaData.groupJsonParameter = parameters;
    }
    const newArray = [...parameterDataTable, paramaData];
    console.log('newArraynewArray', newArray)
    setParameterDataTable(newArray);
    setGroupParameters([]);
    setCustomFormData(initialCustomData);
    setTimeout(() => {
      setCustomFormData((prevData) => {
        return {
          ...prevData,
          [sectionIndex]: {
            ...prevData[sectionIndex],
            smpl_set_smpljson: paramaData["smpl_set_smpljson"],
            smpl_set_smpljson_name: paramaData["smpl_set_smpljson_name"],
          },
        };
      });
    }, 10);
  };
  const addNewSampleSetDetails = () => {
    if (parameterDataTable.length == 0) {
      toast.error("Please Add at least 1 parameter Details", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    setIsSubmit(true);
  };
  const openDeletePopup = () => {
    let headingMsg = "Confirmation!";
    let titleMsg = "Are you sure you want to submit?";
    return (
      <DeleteConfirmation
        isOpen={isSubmit}
        handleClose={setIsSubmit}
        handleConfirm={() => createAssignmentHandler(parameterDataTable)}
        popupMessage={titleMsg}
        popupHeading={headingMsg}
        isOverlayLoader={isOverlayLoader}
      />
    );
  };

  const onCustomChangeHandler = (indexNo, name, value) => {
    console.log('indexNo, name, value', indexNo, name, value)
    setCustomFormData((prevData) => {
      return {
        ...prevData,
        [indexNo]: {
          ...prevData[indexNo],
          [name]: value,
        },
      };
    });
    if (name == "is_group_param") {
      setCustomFormData((prevData) => {
        return {
          ...prevData,
          [indexNo]: {
            ...prevData[indexNo],
            [name + "_name"]: value,
          },
        };
      });
    }
    if (customFormData[sectionIndex].is_group_param == "Parameter" && name === "smpl_set_testmethodjson") {
      getGroupParameterMasterDataWithTestMethod(value)
    }
    else if (customFormData[sectionIndex].is_group_param == "Group") {
      let beforeLastPart = name.slice(0, name.lastIndexOf('_'));
      if (beforeLastPart === "smpl_set_testmethodjson") {
        let lastPart = name.slice(name.lastIndexOf('_') + 1);
        getGroupParameterMasterDataWithTestMethod(value, lastPart)
      }
    }
  };
  useEffect(() => {
    if (customFormData[sectionIndex]?.smpl_set_paramjson) {
      getGroupParameterMasterData(customFormData[sectionIndex]?.smpl_set_paramjson)
    }

  }, [customFormData[sectionIndex]?.smpl_set_paramjson])
  useEffect(() => {
    if (customFormData[sectionIndex]?.smpl_set_groupjson) {
      getGroupParameterMasterData(customFormData[sectionIndex]?.smpl_set_groupjson)
    }
  }, [customFormData[sectionIndex]?.smpl_set_groupjson])
  const getGroupParameterMasterData = async (value) => {
    setIsGroupChanged(false);
    setGroupParameters([]);
    try {
      setIsLoading(true);
      let tempBody = {
        lab_id: formData[1].jrf_lab,
        commodity_id: formData[1].jrf_commodity,
      };
      if (customFormData[sectionIndex].is_group_param == "Group") {
        tempBody.group_id = value;
      } else {
        tempBody.param_id = value;
      }
      let res = await postDataFromApi(labGroupsStdBasisApi, tempBody);
      if (res.data && res.data.status == 200) {
        const actualResponse = res.data.data;
        if (customFormData[sectionIndex].is_group_param == "Group") {
          setGroupParameters(actualResponse.parameters);
          const existingFormData = customFormData[sectionIndex];
          console.log('existingFormData', existingFormData)
          setCustomFormData({
            [sectionIndex]: {
              smpl_set_basisjson: [],
              is_group_param: "Group",
              is_group_param_name: "Group",
              smpl_set_paramjson: "",
              smpl_set_testmethodjson: "",
              smpl_set_unit: "",
              smpl_set_smpljson: existingFormData.smpl_set_smpljson,
              smpl_set_groupjson: existingFormData.smpl_set_groupjson
            },
          });
          actualResponse.parameters.map((groupParam, gpindex) => {
            let standards = groupParam.standard || [];
            let basis =[];
            standards = standards.filter((group) => {
              group.name = group.std_name;
              group.id = group.std_id;
              return true;
            });
            // basis = basis.filter((group) => {
            //   group.name = group.basis_code;
            //   group.id = group.basis_id;
            //   return true;
            // });
            const groupsData = {
              model: "smpl_set_testmethodjson_" + gpindex,
              data: standards,
            };
            const basisData = {
              model: "smpl_set_basisjson_" + gpindex,
              data: basis,
            };
            const unitspValue = groupParam.param_unit.split(',')
            let unitoptions = []
            unitspValue.map((opt) => {
              unitoptions.push({
                name: opt,
                id: opt
              })
            })
            const smlpUnits = {
              model: "smpl_set_unit_" + gpindex,
              data: unitoptions,
            };
            setCustomFormData((prevData) => {
              return {
                ...prevData,
                [sectionIndex]: {
                  ...prevData[sectionIndex],
                  ["smpl_set_basisjson_" + gpindex]:
                    basis.length == 1 ? [basis[0].id] : [],
                  ["smpl_set_basisjson_name"]:
                    basis.length == 1 ? [basis[0].basis_code] : [],
                  ["smpl_set_testmethodjson_" + gpindex]:
                    standards.length == 1 ? standards[0].id.toString() : "",
                  ["smpl_set_testmethodjson_name"]:
                    standards.length == 1 ? standards[0].std_name : "",
                  ["smpl_set_unit_" + gpindex]:
                    unitspValue[0],
                },
              };
            });
            setUpdatedMasterOptions((prev) => [...prev, groupsData, basisData, smlpUnits]);
            if(standards.length == 1){
              getGroupParameterMasterDataWithTestMethod(standards[0].id.toString(),gpindex,groupParam.param_id)
            }
          });
        } else {
          let standards = actualResponse.standard || [];
          let basis = [];
          standards = standards.filter((group) => {
            group.name = group.std_name;
            group.id = group.std_id;
            return true;
          });
          // basis = basis.filter((group) => {
          //   group.name = group.basis_code;
          //   group.id = group.basis_id;
          //   return true;
          // });
          const basisData = {
            model: "smpl_set_basisjson",
            data: basis,
          };
          const groupsData = {
            model: "smpl_set_testmethodjson",
            data: standards,
          };

          const unitspValue = actualResponse.param_unit.split(',')
          let unitoptions = []
          unitspValue.map((opt) => {
            unitoptions.push({
              name: opt,
              id: opt
            })
          })
          const smlpUnits = {
            model: "smpl_set_unit",
            data: unitoptions,
          };
          setCustomFormData((prevData) => {
            return {
              ...prevData,
              [sectionIndex]: {
                ...prevData[sectionIndex],
                // ["smpl_set_basisjson"]: basis.length == 1 ? [basis[0].id] : [],
                // ["smpl_set_basisjson_name"]:
                //   basis.length == 1 ? [basis[0].basis_code] : [],
                ["smpl_set_testmethodjson"]:
                  standards.length == 1 ? standards[0].id.toString() : "",
                ["smpl_set_testmethodjson_name"]:
                  standards.length == 1 ? standards[0].std_name : "",
                ["smpl_set_unit"]:
                  unitspValue[0],
              },
            };
          });
          setUpdatedMasterOptions((prev) => [...prev, groupsData, basisData, smlpUnits]);
          if (standards.length == 1) {
            getGroupParameterMasterDataWithTestMethod(standards[0].id.toString())
          }

        }
      }
    } catch (error) { }
    setTimeout(() => {
      setIsGroupChanged(true);
      setIsLoading(false);
    }, [10]);
  };

  const getGroupParameterMasterDataWithTestMethod = async (value, gpIndex = "",param_id="") => {
    try {
      setIsLoading(true);
      let tempBody = {
        lab_id: formData[1].jrf_lab,
        commodity_id: formData[1].jrf_commodity,
      };
      if (customFormData[sectionIndex].is_group_param == "Group") {
        tempBody.group_id = customFormData[sectionIndex]?.smpl_set_groupjson;
        tempBody.param_id = param_id ? param_id :groupParameteres[gpIndex]?.param_id
      } else {
        tempBody.param_id = customFormData[sectionIndex]?.smpl_set_paramjson;
      }
      tempBody.std_id = value
      let res = await postDataFromApi(labparambasisApi, tempBody);
      if (res.data && res.data.status == 200) {
        const actualResponse = res.data.data;

        let basis = actualResponse.basis || [];
        basis = basis.filter((group) => {
          group.name = group.basis_code;
          group.id = group.basis_id;
          return true;
        });
        const basisData = {
          model: customFormData[sectionIndex].is_group_param == "Group" ? ("smpl_set_basisjson_" + gpIndex) : "smpl_set_basisjson",
          data: basis,
        };
        setUpdatedMasterOptions((prev) => [...prev, basisData]);
        setCustomFormData((prevData) => {
          return {
            ...prevData,
            [sectionIndex]: {
              ...prevData[sectionIndex],
              [customFormData[sectionIndex].is_group_param == "Group" ? ("smpl_set_basisjson_" + gpIndex) : "smpl_set_basisjson"]:
                basis.length == 1 ? [basis[0].id] : [],
              ["smpl_set_basisjson_name"]:
                basis.length == 1 ? [basis[0].basis_code] : [],
            },
          };
        });
      }
    } catch (error) { }
    setTimeout(() => {
      setIsLoading(false);
    }, [10]);
  };
  const createAssignmentHandler = async (parameterData) => {
    let smpl_set_smpljson = [];
    let smpl_set_paramjson = [];
    let smpl_set_groupjson = [];
    let param_sequance_no = 0
    parameterData.map((paramData, seqIndex) => {
      paramData.smpl_set_smpljson.map((sample) => {
        if (!smpl_set_smpljson.includes(sample)) {
          smpl_set_smpljson.push(sample);
        }
      });
      let basis = [];
      let basiscodes = [];
      if (paramData.smpl_set_basisjson_name.length > 0) {
        basiscodes = paramData.smpl_set_basisjson_name.split(",");
      } else {
        basiscodes = [];
      }
      paramData.smpl_set_basisjson.map((basId, i) => {
        basis.push({
          basis_id: basId,
          basis_code: basiscodes[i],
        });
      });
      if (paramData["is_group_param"] == "Group") {
        const groupParamJson = paramData.groupJsonParameter.filter((singleparam) => {
          singleparam.param_sequence = param_sequance_no
          param_sequance_no++;
          return true
        })

        smpl_set_groupjson.push({
          group_id: paramData.smpl_set_groupjson,
          group_name: paramData.smpl_set_groupjson_name,
          parameters: groupParamJson,

          sequanceNo: seqIndex,
        });
      } else {
        smpl_set_paramjson.push({
          param_id: paramData.smpl_set_paramjson,
          param_name: paramData.smpl_set_paramjson_name,
          std_id: paramData.smpl_set_testmethodjson,
          std_name: paramData.smpl_set_testmethodjson_name,
          basis: basis,
          sequanceNo: seqIndex,
          param_unit: paramData.smpl_set_unit,
          param_sequence: param_sequance_no
        });
        param_sequance_no++;
      }
    });
    const newMainPayload = {
      sample_inward_id: formData[1]?.sampleInwardIdMain,
      sample_set_data: {
        smpl_set_smpljson: smpl_set_smpljson,
        smpl_set_groupjson: smpl_set_groupjson,
        smpl_set_testmethodjson: [],
        smpl_set_basisjson: [],
        smpl_set_paramjson: smpl_set_paramjson,
        tenant: GetTenantDetails(1)
      },
    };

    setIsLoading(true);
    setIsOverlayLoader(true)
    const res = await postDataFromApi(sampleDetailsAPI, newMainPayload);
    if (res.data.status === 200) {
      getInwardTabledata(formData[1]?.sampleInwardIdMain);
      setParameterDataTable([]);
      setCustomFormData(initialCustomData);
      setIsSubmit(false);
      setIsOverlayLoader(false)
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsOverlayLoader(false)
    setIsLoading(false);
  };
  const getInwardTabledata = async (simpleId) => {
    setIsOverlayLoader(true)
    let payload = {
      smpl_inwrd_id: simpleId,
    };
    let res = await postDataFromApi(sampleInwardDetailsGetAPI, payload);
    if (res.data.status === 200) {
      const updatedFormData = { ...formData };
      let selectedSimpleIds = [];
      res.data.data.sample_set_data.forEach((singleInwardData, i) => {
        singleInwardData.smpl_set_smpljson.map((simpleId) => {
          selectedSimpleIds.push(simpleId);
        });

        if (!updatedFormData[sectionIndex]) {
          updatedFormData[sectionIndex] = {};
        }
        section.rows.forEach((row) => {
          row.forEach((columnName) => {
            const fieldName = `${columnName.name}_${i}`;
            const value = singleInwardData[columnName.name];
            updatedFormData[sectionIndex][fieldName] = value;
          });
        });
      });
      const newArray = res.data.data.sample_set_data;
      let FinalCombinedArray = [];
      newArray.map((singleParamaSet) => {
        let combinedArray = [];
        for (const [key, value] of Object.entries(
          singleParamaSet.smpl_set_groupjson
        )) {
          value.param_type = "Group"
          combinedArray.push({ ...value });
        }
        for (const [key, value] of Object.entries(
          singleParamaSet.smpl_set_paramjson
        )) {
          value.param_type = "Paramater"
          combinedArray.push({ ...value });
        }
        combinedArray = combinedArray.sort((a, b) => a.sequanceNo - b.sequanceNo);
        FinalCombinedArray.push(combinedArray)
      });
      console.log('FinalCombinedArray', FinalCombinedArray)
      setFinalParamDataSort(FinalCombinedArray)
      setSampleDataTable(newArray);
      setTableData(newArray);
      updatedFormData[0]["smpl_status"] = res.data.data.smpl_status;
      updatedFormData[0]["smpl_inwrd_No"] = res.data.data.smpl_inward_number;
      updatedFormData[0]["inward_msfm_number"] =
        res.data.data.inward_msfm_number;
      setFormData(updatedFormData);
      setSelectedOptions(selectedSimpleIds);
    }
    setIsOverlayLoader(false)
  };
  useEffect(() => {
    setTimeout(() => {
      getSampleOptionData();
    }, 1000);
  }, [masterOptions, selectedOptions]);
  const getSampleOptionData = () => {
    let simplaMasterData = masterOptions?.find((model, index) => {
      if (model.model === "smpl_set_smpljson") {
        return model;
      }
    });
    if (simplaMasterData) {
      let notSelectedOptions = simplaMasterData?.data.filter((simpleId) => {
        return !selectedOptions.includes(simpleId.name);
      });

      let filterData = masterOptions.filter((model) => {

        return true;
      });
      if (notSelectedOptions.length == 0) {
        setIsDisplayNewAddOption(false);
      } else {
        setIsDisplayNewAddOption(true);
      }
      setUpdatedMasterOptions(filterData);
      return;
    }
    setUpdatedMasterOptions(masterOptions);
  };
  const onActionHandleClick = async (actionSelected) => {
    assignmentPageHandleAction(
      actionSelected,
      tableData,
      simpleInwardId,
      setSaveClicked,
      getInwardTabledata,
      setEditableIndex,
      popupIndex,
      getSampleIdsMasterData,
      setIsOverlayLoader
    );
  };
  const removeGroupParameter = (gpindex) => {
    if (gpindex > -1) {
      groupParameteres.splice(gpindex, 1);
    }
    setIsLoading(true);
    const updatedFormData = { ...customFormData };
    delete updatedFormData[1]["smpl_set_basisjson_" + groupParameteres.length];
    delete updatedFormData[1][
      "smpl_set_testmethodjson_" + groupParameteres.length
    ];
    console.log("updatedFormDataupdatedFormData", updatedFormData);
    setCustomFormData(updatedFormData);
    setTimeout(() => {
      groupParameteres.map((groupParam, gpindex) => {
        let standards = groupParam.standard || [];
        let basis = groupParam.basis || [];
        standards = standards.filter((group) => {
          group.name = group.std_name;
          group.id = group.std_id;
          return true;
        });
        basis = basis.filter((group) => {
          group.name = group.basis_code;
          group.id = group.basis_id;
          return true;
        });
        const groupsData = {
          model: "smpl_set_testmethodjson_" + gpindex,
          data: standards,
        };
        const basisData = {
          model: "smpl_set_basisjson_" + gpindex,
          data: basis,
        };
        setCustomFormData((prevData) => {
          return {
            ...prevData,
            [sectionIndex]: {
              ...prevData[sectionIndex],
              ["smpl_set_basisjson_" + gpindex]:
                basis.length == 1 ? [basis[0].id] : [],
              ["smpl_set_basisjson_name"]:
                basis.length == 1 ? [basis[0].basis_code] : [],
              ["smpl_set_testmethodjson_" + gpindex]:
                standards.length == 1 ? standards[0].id.toString() : "",
              ["smpl_set_testmethodjson_name"]:
                standards.length == 1 ? standards[0].std_name : "",
            },
          };
        });
        setUpdatedMasterOptions((prev) => [...prev, groupsData, basisData]);
      });
      setGroupParameters(groupParameteres);
      setIsLoading(false);
    }, 10);
  };
  const getGroupParameterElement = (cell, rowIndex, cellIndex, type) => {
    if (!isGroupChanged) {
      return;
    }
    if (type !== "deleteIcon") {
      cell.customOptions = [];
    }
    let elementData = [];
    elementData = groupParameteres.map((groupParam, gpindex) => {
      if (type != "standard" && type !== "deleteIcon") {
        cell.multiple = true;
      }

      return type === "deleteIcon" ? (
        groupParameteres.length > 1 && (
          <div className="actionOptions deleteGroupParameter">
            <button
              type="button"
              onClick={() => removeGroupParameter(gpindex)}
              className="invisible-button"
              aria-label={"Delete"}
            >
              <i className={"bi bi-trash3-fill"} title={"Delete"}></i>
            </button>
          </div>
        )
      ) : (
        <div className={type === "basis" ? "assignmentGroupParameter" : ""}>
          <RenderFields
            field={cell}
            sectionIndex={sectionIndex}
            fieldIndex={rowIndex * 100 + cellIndex}
            formData={customFormData}
            handleFieldChange={onCustomChangeHandler}
            formErrors={formErrors} // Pass formErrors to RenderFields
            ///for render table only
            renderTable={true}
            tableIndex={rowIndex}
            customName={cell.name + "_" + gpindex}
            masterOptions={updatedMasterOptions}
            from="Table"
          />
        </div>
      );
    });
    return elementData;
  };
  const getSelectedOptions = (name) => {
    let selectedIds = [];
    parameterDataTable.map((singleParam) => {
      if (singleParam[name]) {
        selectedIds.push(singleParam[name]);
      }
    });
    return selectedIds;
  };
  const getSingleGroupParamData = () => {

  }
  return (
    <div key={sectionIndex} className="row my-2">
      {sampleDataTable.length > 0 && (
        <Card className="Scrollable">
          <CardBody>
            <CardTitle tag="h5">{section.title}</CardTitle>
            <table className="table table-white responsive borderless no-wrap align-middle renderTable">
              <thead>
                <tr>
                  <th>Set No.</th>
                  <th>Samples</th>
                  <th>Type</th>
                  {section.headers.map((header, headerIndex) =>
                    header.name != "is_group_param" ? (
                      ["smpl_set_testmethodjson"].includes(header.name) && GetTenantDetails(1, 1) !== "TPBPL" ? (
                        <>
                          <th key={"groups-header" + headerIndex}>Parameter</th>
                          <th key={"groups-header" + headerIndex}>
                            {header.label}
                          </th>
                        </>
                      ) : GetTenantDetails(1, 1) === "TPBPL" && header.label === "Basis" ? null : (
                        <th key={"groups-header" + headerIndex}>
                          {GetTenantDetails(1, 1) === "TPBPL" ? (header.label === "Is Groups or Parameter" ? "Discipline Or Group" : header.label === "Groups / Parameters" ? "Parameter" : header.label) : header.label}
                        </th>
                      )
                    ) : null
                  )}
                  {action != "View" ? <th>Action</th> : null}
                </tr>
              </thead>
              <tbody>
                {
                  finalParamDataSort.map((singleSetData, setIndex) =>
                    singleSetData.map((paramData, setParamIndex) => {
                      let groupCount = 0;
                      sampleDataTable[setIndex].smpl_set_groupjson.map(
                        (singleGroup) => {
                          groupCount += singleGroup.parameters.length;
                        }
                      );
                      let mainRowsPan = 1;
                      mainRowsPan = sampleDataTable[setIndex].smpl_set_paramjson
                        .length +
                        sampleDataTable[setIndex].smpl_set_groupjson
                          .length +
                        groupCount

                      return section.rows.map((row, rowIndex2) => (
                        <>
                          <tr
                            key={"rowIndex" + rowIndex2}
                            className="border-top"
                          >
                            {setParamIndex == 0 && (
                              <React.Fragment>
                                <td
                                  rowSpan={
                                    mainRowsPan
                                  }
                                >
                                  {setIndex + 1}
                                </td>
                                <td
                                  rowSpan={
                                    mainRowsPan
                                  }
                                >
                                  {Array.isArray(
                                    sampleDataTable[setIndex]["smpl_set_smpljson"]
                                  )
                                    ? getSelectedOptionName(
                                      [],
                                      masterOptions,
                                      "smpl_set_smpljson",
                                      sampleDataTable[setIndex]["smpl_set_smpljson"],
                                      "smpl_set_smpljson"
                                    )
                                    : sampleDataTable[setIndex]["smpl_set_smpljson"]}
                                </td>
                              </React.Fragment>
                            )}
                            {
                              paramData.param_type === "Group" ? (
                                <>
                                  <td rowSpan={paramData.parameters.length + 1}>
                                    Group
                                  </td>
                                  <td rowSpan={paramData.parameters.length + 1}>
                                    {paramData["group_name"]}
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>
                                    Paramater
                                  </td>
                                  {
                                    row.map((cell, cellIndex) =>
                                      cell.subname != "group_id" &&
                                        cell.subname != "isGroup" ? (
                                        ["smpl_set_testmethodjson"].includes(
                                          cell.name
                                        ) ? (
                                          <>
                                            {GetTenantDetails(1, 1) !== "TPBPL" && <td>--</td>}
                                            <td key={"cellIndex" + cellIndex}>
                                              {cell.subname == "basis"
                                                ? paramData[cell.subname].map(
                                                  (basecode, index) =>
                                                    (index != 0 ? "," : "") +
                                                    basecode.basis_code
                                                )
                                                : paramData[cell.subname]}
                                            </td>
                                          </>
                                        ) : GetTenantDetails(1, 1) === "TPBPL" && cell.subname === "basis" ? null : (
                                          <td key={"cellIndex" + cellIndex}>
                                            {cell.subname == "basis"
                                              ? paramData[cell.subname].map(
                                                (basecode, index) =>
                                                  (index != 0 ? "," : "") +
                                                  basecode.basis_code
                                              )
                                              : paramData[cell.subname]}
                                          </td>
                                        )
                                      ) : null
                                    )
                                  }
                                  {
                                    setParamIndex == 0 && action != "View" && (
                                      <td
                                        rowSpan={
                                          mainRowsPan
                                        }
                                      >
                                        <div className="actionColumn">

                                          <ActionOptionsTable
                                            actions={
                                              (editableIndex === 0 &&
                                                setIndex === 0) ||
                                                editableIndex === setIndex
                                                ? EditAction
                                                : editableIndex === 0 || editableIndex
                                                  ? []
                                                  : MainAction
                                            }
                                            onActionHandleClick={onActionHandleClick}
                                            setPopupIndex={setPopupIndex}
                                            useFor="Edit"
                                            editableIndex={editableIndex}
                                            popupIndex={popupIndex}
                                            popupMessages={popupMessages}
                                            saveClicked={saveClicked}
                                            tableIndex={setIndex}
                                            isCustomSave={0}
                                            setEditableIndex={setEditableIndex}
                                            getInwardTabledata={getInwardTabledata}
                                            simpleInwardId={simpleInwardId}
                                            moduleType={moduleType}
                                          />

                                        </div>
                                      </td>
                                    )
                                  }
                                </>
                              )
                            }
                          </tr>
                          {paramData.param_type === "Group" && paramData.parameters.map((basecode, index) => (
                            <tr>
                              {row.map((cell, cellIndex) =>
                                cell.subname != "group_id" &&
                                  cell.subname != "isGroup" &&
                                  cell.subname != "param_name" ? (
                                  ["smpl_set_testmethodjson"].includes(
                                    cell.name
                                  ) ? (
                                    <>
                                      <td>{basecode.param_name}</td>
                                      <td key={"cellIndex" + cellIndex}>
                                        {basecode.standards.map(
                                          (std, i) =>
                                            (i != 0 ? "," : "") + std.std_name
                                        )}
                                      </td>
                                    </>
                                  ) : cell.name === "smpl_set_unit" ? <td>{basecode.param_unit}</td> : (
                                    <td key={"cellIndex" + cellIndex}>
                                      {
                                        (cell.subname == "basis" ||
                                          cell.subname == "std_name")
                                          ? cell.subname == "basis"
                                            ? basecode.basis.map(
                                              (base, i) =>
                                                (i != 0 ? "," : "") +
                                                base.basis_code
                                            )
                                            : // : basecode.standards.std_name
                                            basecode.standards.map(
                                              (std, i) =>
                                                (i != 0 ? "," : "") +
                                                std.std_name
                                            )
                                          : cell.subname == "param_name"
                                            ? paramData["group_name"]
                                            : paramData[cell.subname]}
                                    </td>
                                  )
                                ) : null
                              )}
                              {setParamIndex == 0 &&
                                index == 0 && action != "View" && (
                                  <td
                                    rowSpan={
                                      mainRowsPan - 1
                                    }
                                  >
                                    <div className="actionColumn">
                                      <ActionOptionsTable
                                        actions={
                                          (editableIndex === 0 &&
                                            setIndex === 0) ||
                                            editableIndex === setIndex
                                            ? EditAction
                                            : editableIndex === 0 ||
                                              editableIndex
                                              ? []
                                              : MainAction
                                        }
                                        onActionHandleClick={
                                          onActionHandleClick
                                        }
                                        setPopupIndex={setPopupIndex}
                                        useFor="Edit"
                                        editableIndex={editableIndex}
                                        popupIndex={popupIndex}
                                        popupMessages={popupMessages}
                                        saveClicked={saveClicked}
                                        tableIndex={setIndex}
                                        isCustomSave={0}
                                        setEditableIndex={setEditableIndex}
                                        getInwardTabledata={
                                          getInwardTabledata
                                        }
                                        simpleInwardId={simpleInwardId}
                                        moduleType={moduleType}
                                      />
                                    </div>
                                  </td>
                                )}
                            </tr>
                          ))}
                        </>
                      ));
                    })
                  )
                }
              </tbody>
            </table>
          </CardBody>
        </Card>
      )}
      {isDisplayNewAddOption &&
        moduleType !== "sampleverification" &&
        action != "View" && (
          <Card
            className="Scrollable addNewSetData"
            style={{ position: "relative" }}
          >
            <CardBody>
              <CardTitle tag="h5">{section.title}</CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6">
              </CardSubtitle>
              {isLoading && <Loader />}


              <RenderFields
                field={{
                  width: 4,
                  name: "smpl_set_smpljson",
                  label: "Samples",
                  type: "select",
                  options: [],
                  required: true,
                  fieldWidth: 100,
                  multiple: true,
                }}
                sectionIndex={sectionIndex}
                fieldIndex={1 * 100 + 1}
                formData={customFormData}
                handleFieldChange={onCustomChangeHandler}
                formErrors={formErrors} // Pass formErrors to RenderFields
                ///for render table only
                renderTable={true}
                tableIndex={sectionIndex}
                customName={""}
                masterOptions={updatedMasterOptions}
                exludeOptions={selectedOptions}
              // from="Table"
              />
              {/* </div> */}
              <table className="table table-white responsive borderless no-wrap align-middle renderTable">
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    {section.headers.map((header, headerIndex) =>
                      ["smpl_set_testmethodjson"].includes(header.name) && GetTenantDetails(1, 1) !== "TPBPL" ? (
                        <>
                          <th
                            key={"headerIndex" + headerIndex}
                            className={section.rows[0]}
                          >
                            Parameter
                          </th>
                          <th key={"headerIndex" + headerIndex}>
                            {header.label}
                          </th>
                        </>
                      ) : GetTenantDetails(1, 1) === "TPBPL" && header.label === "Basis" ? null : (
                        <th key={"headerIndex" + headerIndex}>
                          {GetTenantDetails(1, 1) === "TPBPL" ? (header.label === "Is Groups or Parameter" ? "Discipline Or Group" : header.label === "Groups / Parameters" ? "Parameter" : header.label) : header.label}
                        </th>
                      )
                    )}
                    {action != "View" ? <th>Action</th> : null}
                  </tr>
                </thead>
                <tbody>
                  {parameterDataTable.map((paramData, rowIndex) =>
                    section.rows.map((row, rowIndex2) =>
                      paramData.is_group_param == "Group" ? (
                        <>
                          <tr
                            key={"rowIndex" + rowIndex}
                            className="border-top"
                          >
                            <td
                              rowSpan={paramData.groupJsonParameter.length + 1}
                            >
                              {rowIndex + 1}
                            </td>
                            {row.map((cell, cellIndex) =>
                              cell.name == "smpl_set_paramjson" ||
                                cell.name == "smpl_set_groupjson" ? (
                                (cell.name == "smpl_set_groupjson" &&
                                  paramData.is_group_param == "Group") ||
                                  (cell.name == "smpl_set_paramjson" &&
                                    paramData.is_group_param == "Parameter") ? (
                                  <td
                                    key={"cellIndex" + cellIndex}
                                    rowSpan={
                                      paramData.groupJsonParameter.length + 1
                                    }
                                  >
                                    {paramData[cell.name + "_name"]}
                                  </td>
                                ) : null
                              ) : [
                                "smpl_set_testmethodjson",
                                "smpl_set_basisjson",
                                "smpl_set_unit",
                              ].includes(cell.name) ? null : (
                                <td
                                  key={"cellIndex" + cellIndex}
                                  rowSpan={
                                    paramData.groupJsonParameter.length + 1
                                  }
                                >
                                  {paramData[cell.name + "_name"]}
                                </td>
                              )
                            )}
                          </tr>
                          {paramData.groupJsonParameter.map(
                            (groupParam, gpIndex) => (
                              <tr
                                key={"rowIndex" + rowIndex}
                                className="border-top"
                              >
                                {row.map((cell, cellIndex) =>
                                  [
                                    "smpl_set_testmethodjson",
                                    "smpl_set_basisjson",
                                    "smpl_set_unit",
                                  ].includes(cell.name) ? (
                                    ["smpl_set_testmethodjson"].includes(
                                      cell.name
                                    ) ? (
                                      <>
                                        <td key={"cellIndex" + cellIndex}>
                                          {groupParam.param_name}
                                        </td>
                                        <td key={"cellIndex" + cellIndex}>
                                          {groupParam.standards.map(
                                            (std, i) =>
                                              (i != 0 ? "," : "") + std.std_name
                                          )}
                                        </td>
                                      </>
                                    ) : cell.name === "smpl_set_unit" ? <td key={"cellIndex" + cellIndex}>
                                      {paramData[cell.name + '_' + gpIndex]}
                                    </td> : (
                                      <td key={"cellIndex" + cellIndex}>
                                        {groupParam.basis.map(
                                          (base, i) =>
                                            (i != 0 ? "," : "") +
                                            base.basis_code
                                        )}
                                      </td>
                                    )
                                  ) : null
                                )}
                                {gpIndex == 0 && (
                                  <td
                                    rowSpan={
                                      paramData.groupJsonParameter.length
                                    }
                                  >
                                    <div className="actionColumn">
                                      {action != "View" ? (
                                        <div className="actionOptions">
                                          <button
                                            type="button"
                                            onClick={() => {
                                              moveParamDetails(rowIndex);
                                            }}
                                            className="invisible-button"
                                            aria-label={"Delete"}
                                          >
                                            <i
                                              className={"bi bi-trash3-fill"}
                                              title={"Delete"}
                                            ></i>
                                          </button>
                                        </div>
                                      ) : null}
                                    </div>
                                  </td>
                                )}
                              </tr>
                            )
                          )}
                        </>
                      ) : (
                        <tr key={"rowIndex" + rowIndex} className="border-top">
                          <td>{rowIndex + 1}</td>
                          {row.map((cell, cellIndex) =>
                            cell.name == "smpl_set_paramjson" ||
                              cell.name == "smpl_set_groupjson" ? (
                              (cell.name == "smpl_set_groupjson" &&
                                paramData.is_group_param == "Group") ||
                                (cell.name == "smpl_set_paramjson" &&
                                  paramData.is_group_param == "Parameter") ? (
                                <td key={"cellIndex" + cellIndex}>
                                  {paramData[cell.name + "_name"]}
                                </td>
                              ) : null
                            ) : cell.name == "smpl_set_unit" ? <td key={"cellIndex" + cellIndex}>
                              {paramData[cell.name]}
                            </td> : paramData.is_group_param == "Group" &&
                              [
                                "smpl_set_testmethodjson",
                                "smpl_set_basisjson",
                              ].includes(cell.name) ? (
                              ["smpl_set_testmethodjson"].includes(
                                cell.name
                              ) ? (
                                <>
                                  <td key={"cellIndex" + cellIndex}>
                                    {paramData.groupJsonParameter.map(
                                      (param) => param.param_name
                                    )}
                                  </td>
                                  <td key={"cellIndex" + cellIndex}>
                                    {paramData.groupJsonParameter.map(
                                      (param, index) =>
                                        param.standards.map(
                                          (std, i) =>
                                            (index != 0 || i != 0 ? "," : "") +
                                            std.std_name
                                        )
                                    )}
                                  </td>
                                </>
                              ) : (
                                <td key={"cellIndex" + cellIndex}>
                                  {paramData.groupJsonParameter.map(
                                    (param, index) =>
                                      param.basis.map(
                                        (base, i) =>
                                          (index != 0 || i != 0 ? "," : "") +
                                          base.basis_code
                                      )
                                  )}
                                </td>
                              )
                            ) : ["smpl_set_testmethodjson"].includes(
                              cell.name
                            ) ? (
                              <>
                                {GetTenantDetails(1, 1) !== "TPBPL" && <td key={"cellIndex" + cellIndex}>--</td>}
                                <td key={"cellIndex" + cellIndex}>
                                  {paramData[cell.name + "_name"]}
                                </td>
                              </>
                            ) : GetTenantDetails(1, 1) === "TPBPL" && cell.name === "smpl_set_basisjson" ? null : (
                              <td key={"cellIndex" + cellIndex}>
                                {paramData[cell.name + "_name"]}
                              </td>
                            )
                          )}
                          <td>
                            <div className="actionColumn">
                              {action != "View" ? (
                                <div className="actionOptions">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      moveParamDetails(rowIndex);
                                    }}
                                    className="invisible-button"
                                    aria-label={"Delete"}
                                  >
                                    <i
                                      className={"bi bi-trash3-fill"}
                                      title={"Delete"}
                                    ></i>
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      )
                    )
                  )}
                  {section.rows.map((row, rowIndex) => {
                    return (
                      <tr key={"rowIndex" + rowIndex} className="border-top">
                        <td>{rowIndex + 1 + parameterDataTable.length}</td>
                        {row.map((cell, cellIndex) => {
                          if (GetTenantDetails(1, 1) == "TPBPL" && cell.name === "is_group_param") {
                            cell.type = "label"
                          }
                          return cell.name == "smpl_set_paramjson" ||
                            cell.name == "smpl_set_groupjson" ? (
                            (cell.name == "smpl_set_groupjson" &&
                              customFormData[sectionIndex].is_group_param ==
                              "Group") ||
                              (cell.name == "smpl_set_paramjson" &&
                                customFormData[sectionIndex].is_group_param ==
                                "Parameter") ? (
                              <td
                                key={"cellIndex" + cellIndex}
                                className={
                                  cell.type === "radio" ? "radio_adjust" : null
                                }
                              >
                                <RenderFields
                                  field={cell}
                                  sectionIndex={sectionIndex}
                                  fieldIndex={rowIndex * 100 + cellIndex}
                                  formData={customFormData}
                                  handleFieldChange={onCustomChangeHandler}
                                  formErrors={formErrors} // Pass formErrors to RenderFields
                                  ///for render table only
                                  renderTable={true}
                                  tableIndex={rowIndex}
                                  customName={cell.name}
                                  masterOptions={updatedMasterOptions}
                                  from="Table"
                                  exludeOptions={getSelectedOptions(cell.name)}
                                />
                              </td>
                            ) : null
                          ) : !(
                            customFormData[sectionIndex].is_group_param ==
                            "Group" &&
                            [
                              "smpl_set_testmethodjson",
                              "smpl_set_basisjson",
                              'smpl_set_unit'
                            ].includes(cell.name)
                          ) ? (
                            ["smpl_set_testmethodjson"].includes(cell.name) ? (
                              <>
                                {GetTenantDetails(1, 1) !== "TPBPL" && <td>-</td>}
                                <td
                                  key={"cellIndex" + cellIndex}
                                  className={
                                    cell.type === "radio" ? "radio_adjust" : null
                                  }
                                >
                                  {" "}
                                  <RenderFields
                                    field={cell}
                                    sectionIndex={sectionIndex}
                                    fieldIndex={rowIndex * 100 + cellIndex}
                                    formData={customFormData}
                                    handleFieldChange={onCustomChangeHandler}
                                    formErrors={formErrors} // Pass formErrors to RenderFields
                                    ///for render table only
                                    renderTable={true}
                                    tableIndex={rowIndex}
                                    customName={cell.name}
                                    masterOptions={updatedMasterOptions}
                                    from="Table"
                                  />
                                </td>
                              </>
                            ) : (
                              <td
                                key={"cellIndex" + cellIndex}
                                className={
                                  cell.type === "radio" ? "radio_adjust" : null
                                }
                              >
                                {" "}
                                <RenderFields
                                  field={cell}
                                  sectionIndex={sectionIndex}
                                  fieldIndex={rowIndex * 100 + cellIndex}
                                  formData={customFormData}
                                  handleFieldChange={onCustomChangeHandler}
                                  formErrors={formErrors} // Pass formErrors to RenderFields
                                  ///for render table only
                                  renderTable={true}
                                  tableIndex={rowIndex}
                                  customName={cell.name}
                                  masterOptions={updatedMasterOptions}
                                  from="Table"
                                />
                              </td>
                            )
                          ) : ["smpl_set_testmethodjson"].includes(cell.name) ? (
                            <>
                              <td>
                                {groupParameteres.map((groupParam, gpindex) => (
                                  <RenderFields
                                    field={{
                                      name: "smpl_param",
                                      subname: "smpl_param",
                                      type: "label",
                                      required: true,
                                      fieldWidth: 100,
                                      errorlabel: "Test Method",
                                      isStaticValue: true,
                                      value: groupParam.param_name,
                                    }}
                                    sectionIndex={sectionIndex}
                                    fieldIndex={rowIndex * 100 + gpindex}
                                    formData={customFormData}
                                    handleFieldChange={onCustomChangeHandler}
                                    formErrors={formErrors} // Pass formErrors to RenderFields
                                    ///for render table only
                                    renderTable={true}
                                    tableIndex={rowIndex}
                                    customName={"smpl_param_" + gpindex}
                                    masterOptions={updatedMasterOptions}
                                    from="Table"
                                  />
                                ))}
                              </td>
                              <td>
                                {getGroupParameterElement(
                                  cell,
                                  rowIndex,
                                  cellIndex,
                                  "standard"
                                )}
                              </td>
                            </>
                          ) : ["smpl_set_unit"].includes(cell.name) ? <td>
                            {groupParameteres.map((groupParam, gpindex) => (
                              <RenderFields
                                field={{
                                  name: "smpl_set_unit",
                                  subname: "smpl_set_unit",
                                  type: "select",
                                  required: true,
                                  fieldWidth: 100,
                                  errorlabel: "Test Method",
                                }}
                                sectionIndex={sectionIndex}
                                fieldIndex={rowIndex * 100 + gpindex}
                                formData={customFormData}
                                handleFieldChange={onCustomChangeHandler}
                                formErrors={formErrors} // Pass formErrors to RenderFields
                                ///for render table only
                                renderTable={true}
                                tableIndex={rowIndex}
                                customName={"smpl_set_unit_" + gpindex}
                                masterOptions={updatedMasterOptions}
                                from="Table"
                              />
                            ))}
                          </td> : GetTenantDetails(1, 1) !== "TPBPL" ? (
                            <td className={"groupBasisTd"}>
                              {getGroupParameterElement(
                                cell,
                                rowIndex,
                                cellIndex,
                                "basis"
                              )}
                            </td>
                          ) : null;
                        })}
                        <td>
                          {customFormData[sectionIndex].is_group_param ==
                            "Group" &&
                            getGroupParameterElement("", "", "", "deleteIcon")}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <div className="button_container">
                <button
                  type="button"
                  className="submitBtn btn btn-primary"
                  onClick={() => {
                    addNewSampleSetDetails();
                  }}
                >
                  Submit Set
                </button>
                <button
                  type="button"
                  className="submitBtn btn btn-primary"
                  onClick={() => {
                    addNewParameterDetails();
                  }}
                >
                  <i class="bi bi-plus-circle customAdd"></i> Add
                </button>
              </div>
              {isSubmit && openDeletePopup()}
            </CardBody>
          </Card>
        )}
    </div>
  );
};

RenderAssignmentTableSection.propTypes = {
  section: PropTypes.string,
  sectionIndex: PropTypes.number,
  formData: PropTypes.object,
  handleFieldChange: PropTypes.func,
  addRow: PropTypes.func,
  deleteRow: PropTypes.func,
  deleteColumn: PropTypes.func,
  formErrors: PropTypes.object,
  setFormData: PropTypes.func,
  popupMessages: PropTypes.arrayOf(PropTypes.string),
  pageType: PropTypes.string,
  action: PropTypes.string,
  masterOptions: PropTypes.arrayOf(PropTypes.object),
  saveClicked: PropTypes.bool,
  moduleType: PropTypes.string,
  setTableData: PropTypes.func,
  getAssignmentMasterData: PropTypes.func,
  setSaveClicked: PropTypes.func,
  tableData: PropTypes.arrayOf(PropTypes.object),
  getSampleIdsMasterData: PropTypes.func,
  setIsDisplayNewAddOption: PropTypes.func,
  isDisplayNewAddOption: PropTypes.bool,
  setIsOverlayLoader: PropTypes.func,
  isOverlayLoader: PropTypes.bool
};
export default RenderAssignmentTableSection;
